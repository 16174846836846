import React from "react"
import SEO from "../components/seo"
import { graphql, useStaticQuery, navigate } from "gatsby"
import Img from "gatsby-image"
import { Carousel } from "react-responsive-carousel"
import axios from "axios"

const IndexPage = () => {
  const { logo, images } = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "house-of-praise-logo.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      images: allFile(
        filter: { relativeDirectory: { eq: "backgroundImage" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <SEO title="Welcome" description="Online Submission Form" />
      <Carousel
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        autoPlay
        infiniteLoop
        className="shadow"
      >
        {images.edges.map(({ node }) => {
          return <Img fluid={node.childImageSharp.fluid} />
        })}
      </Carousel>
      <div
        className="container pb-5"
        style={{ top: "0", left: "0", right: "0", bottom: "0" }}
      >
        <div className="my-3" />
        <h1 className="text-center text-warning">Selamat Datang!</h1>
        <div className="my-3" />
        <form
          action="https://docs.google.com/forms/u/4/d/e/1FAIpQLSdrizRXfT8blZ-xeOnx-r-C8jLXgjcb6YOGc7_bD8ZwHb7r-A/formResponse"
          method="POST"
        >
          <div className="form-group">
            <label>Nama</label>
            <input
              className="form-control form-control-lg shadow-sm"
              name="entry.965397000"
              type="text"
            />
          </div>
          <div className="form-group">
            <label>Ulang Tahun</label>
            <input
              className="form-control form-control-lg shadow-sm"
              name="entry.144668573"
              type="text"
            />
          </div>
          <div className="form-group">
            <label>Alamat</label>
            <textarea
              className="form-control form-control-lg shadow-sm"
              name="entry.1533845906"
              rows="1"
            ></textarea>
          </div>
          <div className="form-group ">
            <label>No. HP (WA)</label>
            <input
              className="form-control form-control-lg shadow-sm"
              name="entry.595912999"
              type="number"
            />
          </div>
          <div className="form-group ">
            <label>Ujud Doa</label>
            <input
              className="form-control form-control-lg shadow-sm"
              name="entry.1868894530"
              type="text"
            />
          </div>
          <div className="my-5" />
          <button
            className="btn btn-warning mx-auto d-block btn-lg shadow"
            type="submit"
          >
            Submit
          </button>
        </form>
        <div className="my-5" />
        <Img className="d-block mx-auto" fixed={logo.childImageSharp.fixed} />
      </div>
    </React.Fragment>
  )
}
export default IndexPage
